import { useUserStore } from '@/store/user';
import Cookies from 'js-cookie';

/**
 * Auth middleware.
 * Check the autentication
 */
export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const { $sdk } = useNuxtApp();
  const user = useUserStore();

  const accessToken = Cookies.get('naat');
  const refreshToken = Cookies.get('nprt');

  if (!accessToken && !refreshToken) {
    return navigateTo('/sign_in');
  }

  if (!accessToken && refreshToken) {
    const res = await $sdk.auth.load();
    if (res !== 200) {
      return navigateTo('/sign_in');
    }
  }

  if (!user.info?.id) {
    const res = await $sdk.auth.load();
    if (res !== 200) {
      return navigateTo('/sign_in');
    }
  }
});
